import logo from './logo.svg';
import './App.css';
import Home from './Component/User/home';
import 'bootstrap/dist/css/bootstrap.min.css';
// import TopBar from './Component/User/Nav/TopBar';
import TopBar from './Component/User/Nav/TopBar';
import { Route, Router, BrowserRouter, Routes } from 'react-router-dom';
import AddClient from './Component/User/Client/AddClient';
import ViewClient from './Component/User/Client/ViewClient';
import Quatation from './Component/User/Client/Quatation';
import QuatationSummary from './Component/User/Client/QuatationSummary';
import ViewQuatations from './Component/User/Client/ViewQuatations';
import SignUp from './Component/User/Client/SignUp';
import ProtectedRoute from '../src/Component/User/routes/CheckLogin';
function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="/home" element={<Home />}/>
        <Route path="/client/add" element={<AddClient />} />
        <Route path='/ViewClient' element={<ViewClient />} />
        <Route path='/quatation/:projectId?' element={<Quatation />} />
        <Route path='/quatation-summary' element={<QuatationSummary />} />
        <Route path='/view-quatation' element={<ViewQuatations />} />
      </Routes>
    </BrowserRouter>


  );
}

export default App;
