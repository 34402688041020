import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCog, faQuestionCircle, faEye, faTimes, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('home');
    
    useEffect(() => {
        setActiveLink(location.pathname);

    }, [])
    const handleSidebarClick = (link) => {
        setActiveLink(link);
    }
    const handleSignout = () => {
        localStorage.removeItem('user');
        window.location.href='/';
        // navigate('/login');
    }
    return (
        <nav className="sidebar mt-5 p-2">

            <ul>

                <Link to="/home" onClick={() => handleSidebarClick('home')}
                    className={`${activeLink === '/home' ? 'active' : 'text-blue-400 '} p-2 `} >
                    <FontAwesomeIcon icon={faHome} /> <span>   </span>
                    Home</Link>
                <Link to="/client/add" onClick={() => handleSidebarClick('/client/add')}
                    className={`${activeLink === '/client/add' ? 'active' : 'text-blue-400 '} p-2`}
                >
                    <FontAwesomeIcon icon={faCog} /> <span>   </span>
                    Add Client </Link>
                <Link to="/ViewClient" onClick={() => handleSidebarClick('/ViewClient')}
                    className={`${activeLink === '/ViewClient' ? 'active' : 'text-blue-400 '} p-2`}>
                    <FontAwesomeIcon icon={faUser} /> <span>   </span>
                    View Client</Link>
                <Link to="/quatation" onClick={() => handleSidebarClick('/quatation')}
                    className={`${activeLink === '/quatation' ? 'active' : 'text-blue-400 '} p-2`}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} /> <span>   </span>
                    Quotation</Link>
                <Link to="/quatation-summary"
                    onClick={() => handleSidebarClick('/quatation-summary')}
                    className={`${activeLink === '/quatation-summary' ? 'active' : 'text-blue-400 '} p-2`}
                >
                    <FontAwesomeIcon icon={faTimes} /> <span>   </span>
                    Quotation Summary</Link>
                <Link to="/view-quatation" onClick={() => handleSidebarClick('/view-quatation')}
                    className={`${activeLink === '/view-quatation' ? 'active' : 'text-blue-400 '} p-2`}
                >
                    <FontAwesomeIcon icon={faEye} /> <span>   </span>
                    View Quotation</Link>

                <Link onClick={() => handleSignout()}   className="text-blue-400">
                    <FontAwesomeIcon icon={faSignOutAlt} /> <span>   </span>
                    Sign Out</Link>
            </ul>
        </nav>
    )
}
export default Sidebar