import { Table } from "react-bootstrap";
import TopBar from "../Nav/TopBar";
import { Card } from 'react-bootstrap';
import { useEffect, useState } from "react";
import UserSection from "../../../Helpers/Service/UserSection";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DownloadSummary from "./DownloadSummary";

const QuatationSummary = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState(['']);
    const [projectId, setprojectId] = useState();
    const [showComponent, setshowComponent] = useState(false);
    const [status, setstatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const summaryData = async () => {
        const response = await UserSection.getSummary();
        setdata(response.data);
    }
    const changeStatus = async (item, e) => {
        let value = e.target.value;
        const mergedState = {
            item, // Spread the properties of state1
            value,
        };
        // const response = await UserSection.quatationStatus(mergedState);
        const user = localStorage.getItem('user');
        const response = await axios.get('https://quatationmanagmentapi.testingruminate.co.in/public/api/user/client/quatatonStatus/', mergedState, {
            headers: {
                Authorization: `Bearer ${user}`, // Include the token in the Authorization header
            },
        });
        console.log(response);
    }

    useEffect(() => {
        summaryData();
    }, []);
    const editProject = (id) => {
        navigate(`/quatation/${id}`);
    };
    const downloadexcel = async (id) => {
        try {

            setprojectId(id);
            setstatus(true);
            console.log(projectId);
        } catch (error) {
            setLoading(true);
            console.log(error);
            // handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }

    const disabledownload = () => {
        setstatus(false);
    };
    return (
        <TopBar>
            <div className="col-md-12">
                <Card >
                    <Card.Header>
                        <Card.Title>Quatation Summary</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Download</th>
                                        <th>Quatation Id</th>
                                        <th>User Id</th>
                                        <th>Client Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        < tr >
                                            <td>
                                                {status ? (
                                                    <p>Please wait</p>
                                                ) : (

                                                    <FontAwesomeIcon icon={faDownload} onClick={() => downloadexcel(item.project_id)} />
                                                )}


                                            </td>
                                            <td>{item.project_id}</td>
                                            <td>{item.client_id}</td>
                                            <td>{item.name}</td>
                                            {/* <td>
                                                {item.status == '' ? (
                                                    <select name="status" className="form-control" onChange={(e) => changeStatus(item.id, e)}>
                                                        <option value="pending">Pending</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="void">void</option>
                                                    </select>
                                                ) : (
                                                    <th>{item.status}</th>
                                                )}

                                            </td> */}
                                            <td>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) => editProject(item.id)}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </Table>
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
            <div style={{ display: showComponent ? 'block' : 'none' }}>
                <DownloadSummary project={projectId} onsubmit={disabledownload} />
            </div>
        </TopBar >
    )
}
export default QuatationSummary;