import TopBar from "../Nav/TopBar";
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserSection from '../../../Helpers/Service/UserSection';
import Swal from "sweetalert2";
const AddClient = () => {
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    // const [sitaddress, setSitAddress] = useState();
    const [persons, setperson] = useState();
    const [gst, setGst] = useState();
    const [button, setButtton] = useState('Save');
    const [disable, setDisable] = useState(false);
    const handleSubmit = async (e) => {
        try {
            setButtton('Please wait...')
            setDisable(true);
            e.preventDefault();
            const response = await UserSection.addClient({ name, address, phone, email, gst, persons });
            if (response.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'client',
                    text: 'Client added  successfully',
                    timer: 3000,
                }).then((result) => {
                    if (result) {
                        setButtton('Save');
                        setDisable(false);
                        navigate('/client/add');
                    }
                });
              
            }
        }
        catch (error) {

        }
    };
    return (
        <TopBar>
            <Card >
                <Card.Header>
                    <Card.Title>Add Client</Card.Title>
                </Card.Header>
                <Card.Body>

                    <Card.Text>
                        <Form className="p-3">
                            <Row>
                                <Form.Group className="mb-3 col-6" controlId="formFirstName">
                                    <Form.Label>Client Name: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Client  name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-6" controlId="formLastName">
                                    <Form.Label>Site Address:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter  address"
                                        name="address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 col-6" controlId="formLastName">
                                    <Form.Label>Phone:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter phone no"
                                        name="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-6" controlId="formEmail">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-6" controlId="formEmail">
                                    <Form.Label>Contact Person</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=" contact person name"
                                        name="person"
                                        value={persons}
                                        onChange={(e) => setperson(e.target.value)}
                                    />
                                </Form.Group><Form.Group className="mb-3 col-6" controlId="formEmail">
                                    <Form.Label>Gst Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter gst number"
                                        name="gst"
                                        value={gst}
                                        onChange={(e) => setGst(e.target.value)}
                                    />
                                </Form.Group>

                            </Row>
                        </Form>
                    </Card.Text>

                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" type="submit" className="col-md-2 offset-5" onClick={handleSubmit} disabled={disable}>
                        {button}
                    </Button>
                </Card.Footer>

            </Card>
        </TopBar>
    )
}
export default AddClient;