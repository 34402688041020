import React, { useState, useRef } from 'react';
import TopBar from "../Nav/TopBar";
import { CardFooter, Table } from "react-bootstrap";
import { Form, FormControl, Button } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import UserSection from "../../../Helpers/Service/UserSection";
import ExcelJS from 'exceljs';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Lottie from 'lottie-react';
import animationData from '../../../animations/loader_animation0.json';
import axios from 'axios';
const ViewQuatations = () => {

    const [sectionArr, setSectionArr] = useState([]);
    const [projectId, setprojectId] = useState();
    const [projectData, setprojectData] = useState();
    const [sectionTotal, setSectionTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [excel, setExcel] = useState('Download Excel');
    const [exceldisable, setExceldisable] = useState(false);
    const [pdfDisable, setPdfDisable] = useState(false);
    const tableRef = useRef(null);
    const exportToPDF = () => {
        setPdfDisable(true);
        const input = tableRef.current;

        // Increase font size for the table content
        const fontSize = 16;
        input.style.fontSize = `${fontSize}px`;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            // Set the width and height of the PDF document
            const pdfWidth = 210; // A4 size
            const pdfHeight = 297; // A4 size
            const aspectRatio = canvas.width / canvas.height;
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfHeightAdjusted = pdfWidth / aspectRatio;

            // Set padding values
            const paddingTop = 10;
            const paddingLeft = 10;

            // Add image to PDF with padding
            pdf.addImage(imgData, 'PNG', paddingLeft, paddingTop, pdfWidth - 2 * paddingLeft, pdfHeightAdjusted - 2 * paddingTop);
            pdf.save('table-export.pdf');

            // Reset font size after export
            input.style.fontSize = '';
            setPdfDisable(false);
        });
    };
    const myStyles = {
        width: '55px',
        height: '51px'
    };
    const inlineStyles = {
        paddingLeft: '700px',
    };
    const viewQuation = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            setLoading(true);
            let res = await UserSection.viewProjectById(projectId);
            if (res.status) {
                const sectionsArray = Object.entries(res.data.section);
                setSectionArr(sectionsArray);
                setprojectData(res.data);
                setSectionTotal(Object.keys(res.data.section).length);
                setLoading(false);
            }
            else {
                return 0;
            }

        } catch (error) {
            // handleApiError(error, 'Failed to fetch data. Please try again later.');

        }
    }
    const blobToBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }
    const downloadExcelFile = async () => {
        setExcel('Please wait...')
        setExceldisable(true);
        const workbook = new ExcelJS.Workbook();

        // Add summary worksheet
        const summarySheet = workbook.addWorksheet('Summary');

        // Add new headings and logos
        const borderStyle = {
            top: {
                style: 'medium'
            },
            left: {
                style: 'medium'
            },
            bottom: {
                style: 'medium'
            },
            right: {
                style: 'medium'
            }
        };

        const newHeadings = [
            ["Ruminate It Solotion INDIA PVT. LTD."],
            ["Ruminate It Solotion"],
            ["Quotation"]
        ];

        let currentRow = 1;
        const newHeadingsFontSize = 18;
        const newHeadingsHeight = 40;

        // Fetch the logos for the new headings
        return Promise.all([
            fetch('/no_image.jpeg').then(res => res.blob()),
            fetch('/no_image.jpeg').then(res => res.blob()),
            fetch('/no_image.jpeg').then(res => res.blob())
        ]).then(async ([blob1, blob2, blob3]) => {
            const image1 = await workbook.addImage({
                base64: await blobToBase64(blob1),
                extension: 'png',
            });
            const image2 = await workbook.addImage({
                base64: await blobToBase64(blob2),
                extension: 'png',
            });
            const image3 = await workbook.addImage({
                base64: await blobToBase64(blob3),
                extension: 'png',
            });

            newHeadings.forEach((heading) => {
                const row = summarySheet.addRow(heading);

                if (heading[0] === "Quotation") {
                    row.height = 20; // Reduced height
                } else {
                    row.height = newHeadingsHeight;
                }

                row.getCell(1).font = {
                    bold: true,
                    size: newHeadingsFontSize
                };
                row.getCell(1).alignment = {
                    wrapText: true,
                    horizontal: 'center',
                    vertical: 'middle'
                };
                row.getCell(1).border = borderStyle;
                summarySheet.mergeCells(`A${currentRow}:L${currentRow}`);
                currentRow++;
            });

            // Add the logos next to the "GROHE INDIA PVT. LTD." heading
            summarySheet.addImage(image1, {
                tl: {
                    col: 0.2,
                    row: 0
                },
                ext: {
                    width: 90,
                    height: 53
                }
            });

            summarySheet.addImage(image2, {
                tl: {
                    col: 9,
                    row: 0
                },
                ext: {
                    width: 90,
                    height: 53
                }
            });
            summarySheet.addImage(image3, {
                tl: {
                    col: 10.5,
                    row: 0
                },
                ext: {
                    width: 90,
                    height: 53
                }
            });

            // Merge and add border style for client details
            const mergeAndAddBorder = (label, value, colStart, colEnd) => {
                const cell = summarySheet.getCell(`A${currentRow}`);
                cell.value = label + ': ' + value;
                cell.border = borderStyle;
                summarySheet.mergeCells(`A${currentRow}:${String.fromCharCode(65 + colEnd)}${currentRow}`);
                currentRow++;
            };
            var client_id = projectData ? projectData.client_id : '';
            var site = projectData ? projectData.client.client_name : '';
            var architect = projectData ? projectData.client.afd : '';
            var address = projectData ? projectData.client.add_to : '';
            var project_id = projectData ? projectData.project_id : '';
            mergeAndAddBorder('Client id', client_id, 1, 11);
            mergeAndAddBorder('Site Address', site, 1, 11);
            mergeAndAddBorder('Architect Firm Detail', architect, 1, 11);
            mergeAndAddBorder('Addressed To', address, 1, 11);
            mergeAndAddBorder('Project ID', project_id, 1, 11);
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Add a blank row
            const blankRow = summarySheet.addRow([]);
            blankRow.height = 20; // Set the height for the blank row

            const overallSummaryHeadingArray = ['Particulars', 'MRP', 'Discounted Price']
            // Add row header for Particulars, MRP, and Discounted Price
            const rowHeader = summarySheet.addRow(overallSummaryHeadingArray);

            overallSummaryHeadingArray.forEach((ele, index) => {
                summarySheet.getColumn(index + 1).width = 30;
            })

            rowHeader.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'FF112038'
                    }
                };
                cell.font = {
                    color: {
                        argb: 'FFFFFFFF'
                    },
                    bold: true
                };
                cell.border = borderStyle;
                // Center align both horizontally and vertically
                cell.alignment = {
                    horizontal: 'center',
                    vertical: 'middle'
                };
            });


            //Common Heading for all the sections
            const sectionHeading = ['SKU Code', 'Brand', 'Brand Description', 'Finish', 'Product Image', 'Quantity', 'Area', 'MRP', 'Discounted Price', 'Discount %', 'GST', 'Total'];
            const columnWidths = [20, 14, 55, 10, 15, 9, 13, 9, 17, 12, 7, 12];
            const sectionNamesArray = [];

            const Table = document.getElementById('productTable').getElementsByTagName("tbody")[0];
            console.log(Table);
            const Rows = Table.rows;
            const Promises = [];

            for (let i = 0; i < Rows.length; i++) {
                const row = Rows[i];
                const cellText = row.cells[0].textContent;
                const rowClassName = row.className;

                //If Table Row has section-heading as a classname that means its a heading;
                if (rowClassName == "section-heading") {
                    const individualOverallSummary = [];
                    let sectionRowIndex = 1;
                    const sectionName = sectionNamesArray.includes(cellText.trim()) ? cellText.trim() + "_1" : cellText.trim();
                    sectionNamesArray.push(sectionName)

                    //Section Heading Name to maekt the WorksheetName..
                    const selectionWorksheet = workbook.addWorksheet(sectionName);

                    //Manage the Heading Height for the section.
                    managerHeadingHeight(selectionWorksheet, sectionHeading, borderStyle)

                    individualOverallSummary.push(sectionName);

                    for (let j = 1; ; j++) {

                        const sectionBody = Rows[(i++) + 1];

                        if (sectionBody == undefined) {
                            i = Rows.length;
                            break;
                        }
                        if (sectionBody?.className == "section-heading") {
                            i = i - 1;
                            break;
                        }

                        const rowData = Array.from(sectionBody.cells, cell => {
                            const img = cell.querySelector('img');
                            if (img) {
                                const promise = new Promise((resolve, reject) => {
                                    let base64 = img.src;
                                    // const promise = fetch(img.src)
                                    //     .then(res => res.blob())
                                    //     .then(blobToBase64)
                                    //     .then(base64 => {

                                    setTimeout(function () { resolve(addImgToExcel(base64, sectionRowIndex++, selectionWorksheet, workbook)); }, 3000);

                                });
                                // });
                                Promises.push(promise);
                                return null;
                            } else {
                                return cell.textContent;
                            }
                        });

                        let excelRow = selectionWorksheet.addRow(rowData);
                        excelRow.height = 85;

                        const cellText = sectionBody.cells[0].textContent;

                        if (cellText.toLowerCase().includes('total mrp') || cellText.toLowerCase().includes('total amount') || cellText.toLowerCase().includes('shower area subtotal') || cellText.toLowerCase().includes('basin area subtotal') || cellText.toLowerCase().includes('ewc area subtotal')) {
                            excelRow.height = 20;
                            selectionWorksheet.mergeCells(`A${excelRow.number}:L${excelRow.number}`);

                            if (cellText.toLowerCase().includes('total mrp')) individualOverallSummary.push(managerOverallSummary(cellText))
                            else if (cellText.toLowerCase().includes('total amount')) individualOverallSummary.push(managerOverallSummary(cellText))
                        }

                        for (let columnIndex = 1; columnIndex <= 12; columnIndex++) {
                            const cell = excelRow.getCell(columnIndex);
                            cell.border = borderStyle;
                            cell.alignment = {
                                horizontal: 'center',
                                vertical: 'middle'
                            };
                        }
                    }

                    //Function to Section Summary manage to align Right and height 20px;
                    managerIndividualSectionSummary(selectionWorksheet)

                    columnWidths.forEach((width, i) => {
                        selectionWorksheet.getColumn(i + 1).width = width;
                    });
                    summarySheet.addRow(individualOverallSummary);

                }
            }

            // Wait for all images to be added and then trigger the download
            Promise.all(Promises).then(() => {
                workbook.xlsx.writeBuffer().then(buffer => {
                    const blob = new Blob([buffer], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'exported_data.xlsx';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            });
        });
        setExceldisable(false);
        setExcel('Download Excel');

    }

    // function to add img
    function addImgToExcel(base64, sectionRowIndex, selectionWorksheet, workbook) {
        const image = workbook.addImage({
            base64,
            extension: 'png',
        });
        // const imageTopLeft = { col: index + 0.5};

        selectionWorksheet.addImage(image, {
            tl: {
                // col: 4 + 0.10,
                col: 4 + 0.2,
                row: sectionRowIndex + 0.3,
                // pos: { x: 2, y: 2 },
            }, // Adjust row index due to skipping top 8 rows
            ext: {
                width: 90,
                height: 80,

            }, // Set image dimensions
            editAs: 'oneCell', // Ensure the image is positioned within one cell
        });
    }
    //Function to manage the Summary Section MRP & Total Amount
    function managerOverallSummary(value) {
        const amount = value.split(":")[1];
        return amount;
    }

    //Function to manger the each Section Summary Alignment.
    function managerIndividualSectionSummary(selectionWorksheet) {
        for (let i = 0; i < 5; i++) {
            const sectionSummaryElement = selectionWorksheet.getRow(selectionWorksheet.rowCount - i);
            sectionSummaryElement.getCell(1).alignment = {
                horizontal: 'right',
                vertical: 'middle'
            };
            sectionSummaryElement.height = 20; // Set a smaller height
        }
    }

    //Function to manger the each Section Heading Height..
    function managerHeadingHeight(workBook, sectionHeading, borderStyle) {
        const headerRow = workBook.addRow(sectionHeading);

        headerRow.height = 30; // Set the height for the header row

        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'FF112038'
                }
            };
            cell.font = {
                color: {
                    argb: 'FFFFFFFF'
                },
                bold: true
            };
            cell.border = borderStyle;
            // Center align both horizontally and vertically
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle'
            };
        });
    }

    const downloadPdfFile = async () => {
        try {
            const user = localStorage.getItem('user');
            setLoading(true);
            // let res = await UserSection.downloadPdf(projectId);
            const response = await axios.get('http://127.0.0.1:8000/api/user/client/downloadPdf/P_19_29112023075136', {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${user}`, // Include the token in the Authorization header
                },
            });
            const blob = new Blob([response.data], {
                type: 'application/pdf',

            });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'document.pdf';
            a.click();

            // Release the temporary URL
            window.URL.revokeObjectURL(url);
            if (response.status) {
                setLoading(false);
            }

        }
        catch (error) {

        }
    }
    const resetstate = (e) => {
        setLoading(false);
        e.preventDefault();
        setprojectId('');
        setSectionArr();
        setSectionTotal();
        setprojectData();
        // window.location.href ='/user/viewProjects';
    }
    return (
        <TopBar>
            {
                <div class="loder">
                    {loading ? (
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            autoplay={true}
                            style={{ height: '100px', width: '100px' }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            }
            {
                <div className="col-md-12">
                    <form>
                        <div className="row ml-2">
                            <div className="col-md-4 mb-4">
                                <input type="text" class="form-control" name="projectId" value={projectId} onChange={(e) => setprojectId(e.target.value)} />
                            </div>
                            <div className="col-md-2">
                                <div className="row">
                                    <div className="col-md-2">
                                        <button className="btn btn-primary sm-2" onClick={viewQuation}>Search</button>
                                    </div>
                                    <div className="col-md-2 offset-3">
                                        <button className="btn btn-danger sm-2" onClick={(e) => resetstate(e)}>Reset</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                    <Card >
                        <Card.Header>
                            <Card.Title>View Quatation</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Table ref={tableRef} striped bordered hover id='productTable'>
                                    <thead >
                                        <tr>
                                            <th>Id</th>
                                            <th>Brand</th>
                                            <th>Brand Description</th>
                                            <th>finsh</th>
                                            <th>Image</th>
                                            <th>Quantity</th>
                                            <th>Area</th>
                                            <th>Mrp</th>
                                            <th>Discount Price</th>
                                            <th>Discount %</th>
                                            <th>Gst</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sectionTotal > 0 ?
                                                sectionArr.map(([sectionName, data], index) => (
                                                    data.map((item, itemindex) => (
                                                        data.length == 1 ?

                                                            <><tr className='section-heading'><td colSpan='12' className="border p-2 text-center">{item.section_name}</td></tr>
                                                                <tr className='s1'>
                                                                    <td className="border p-2 text-center">{item.id}</td>
                                                                    <td className="border p-2 text-center">{item.project.brand}</td>
                                                                    <td className="border p-2 text-center">{item.project.product_desc}</td>
                                                                    <td className="border p-2 text-center">{item.project.finish}</td>
                                                                    <td className="border p-2 text-center w-24">{<img src={item.project.image} className='w-24' style={myStyles} />}</td>
                                                                    <td className="border p-2 text-center">{item.qty}</td>
                                                                    <td className="border p-2 text-center">{item.area}</td>
                                                                    <td className="border p-2 text-center">{item.project.mrp}</td>
                                                                    <td className="border p-2 text-center">{item.disc_price}</td>
                                                                    <td className="border p-2 text-center w-24">{item.disc_per}</td>
                                                                    <td className="border p-2 text-center w-24">{item.project.gst}</td>
                                                                    <td className="border p-2 text-center">{item.total}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td colSpan='12' className='border p-2' style={inlineStyles}>Total MRP :{item.project.mrp * item.qty}  </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2  text-end'>Total Amount : {item.total} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2 text-end'>Shower Area Subtotal: {item.shower_area} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2  text-end'>Basin Area Subtotal:   {item.Basin}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2  text-end'>EWC Area Subtotal:  {item.Ewc} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2  text-end'>Kitchen Area Subtotal:  {item.kitchen} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='12' className='border p-2  text-end'>Faucets Area Subtotal:  {item.faucets} </td>
                                                                </tr>
                                                            </>
                                                            :
                                                            itemindex == 0 ?

                                                                <><tr className='section-heading'><td colSpan='12' className="border p-2 text-center">{item.section_name}</td></tr>
                                                                    <tr className='s1'>
                                                                        <td className="border p-2 text-center">{item.id}</td>
                                                                        <td className="border p-2 text-center">{item.project.brand}</td>
                                                                        <td className="border p-2 text-center">{item.project.product_desc}</td>
                                                                        <td className="border p-2 text-center">{item.project.finish}</td>
                                                                        <td className="border p-2 text-center w-24" >{<img src={item.project.image} className='w-24' style={myStyles} />}</td>
                                                                        <td className="border p-2 text-center">{item.qty}</td>
                                                                        <td className="border p-2 text-center">{item.area}</td>
                                                                        <td className="border p-2 text-center">{item.project.mrp}</td>
                                                                        <td className="border p-2 text-center">{item.disc_price}</td>
                                                                        <td className="border p-2 text-center w-24">{item.disc_per}</td>
                                                                        <td className="border p-2 text-center w-24">{item.project.gst}</td>
                                                                        <td className="border p-2 text-center">{item.total.toFixed(2)}</td>
                                                                    </tr>

                                                                </>
                                                                :
                                                                (itemindex + 1) == data.length ?

                                                                    <>
                                                                        <tr className='s1'>
                                                                            <td className="border p-2 text-center">{item.id}</td>
                                                                            <td className="border p-2 text-center">{item.project.brand}</td>
                                                                            <td className="border p-2 text-center">{item.project.product_desc}</td>
                                                                            <td className="border p-2 text-center">{item.project.finish}</td>
                                                                            <td className="border p-2 text-center w-24" >{<img src={item.project.image} className='w-24' style={myStyles} />}</td>
                                                                            <td className="border p-2 text-center">{item.qty}</td>
                                                                            <td className="border p-2 text-center">{item.area}</td>
                                                                            <td className="border p-2 text-center">{item.project.mrp}</td>
                                                                            <td className="border p-2 text-center">{item.disc_price}</td>
                                                                            <td className="border p-2 text-center w-24">{item.disc_per}</td>
                                                                            <td className="border p-2 text-center w-24">{item.project.gst}</td>
                                                                            <td className="border p-2 text-center">{item.total}</td>

                                                                        </tr>


                                                                        <tr>

                                                                            <td colSpan='12' className='border p-2  text-end'>Total MRP :{item.project.mrp * item.qty}  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>Total Amount : {item.totals} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>Shower Area Subtotal: {item.shower_area} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>Basin Area Subtotal:   {item.Basin}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>EWC Area Subtotal:  {item.Ewc} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>Kitchen Area Subtotal:  {item.kitchen} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='12' className='border p-2  text-end'>Faucets Area Subtotal:  {item.faucets} </td>
                                                                        </tr>
                                                                    </>
                                                                    :
                                                                    <tr>
                                                                        <td className="border p-2 text-center">{item.id}</td>
                                                                        <td className="border p-2 text-center">{item.project.brand}</td>
                                                                        <td className="border p-2 text-center">{item.project.product_desc}</td>
                                                                        <td className="border p-2 text-center">{item.project.finish}</td>
                                                                        <td className="border p-2 text-center w-24" >{<img src={item.project.image} className='w-24' style={myStyles} />}</td>
                                                                        <td className="border p-2 text-center">{item.qty}</td>
                                                                        <td className="border p-2 text-center">{item.area}</td>
                                                                        <td className="border p-2 text-center">{item.project.mrp}</td>
                                                                        <td className="border p-2 text-center">{item.disc_price}</td>
                                                                        <td className="border p-2 text-center w-24">{item.disc_per}</td>
                                                                        <td className="border p-2 text-center w-24">{item.project.gst}</td>
                                                                        <td className="border p-2 text-center">{item.total}</td>

                                                                    </tr>

                                                    ))
                                                )) : (
                                                    <div class="loder">{
                                                        loading ? (
                                                            <Lottie
                                                                animationData={animationData}
                                                                loop={true}
                                                                autoplay={true}
                                                                style={{ height: '100px', width: '100px' }}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    </div>
                                                )
                                        }
                                    </tbody>
                                </Table>
                            </Card.Text>

                        </Card.Body>
                        <CardFooter>
                            <div class="row">
                                <div className='col-md-2'>
                                    <button onClick={downloadExcelFile} type="button" className="btn btn-primary offset-1" disabled={exceldisable}>{excel}</button>
                                </div>
                                <div className='col-md-2'>
                                    {/* <button onClick={downloadPdfFile} type="button" className="btn btn-secondary offset-1" disabled={pdfDisable} >Export to Pdf</button> */}
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
            }
        </TopBar>
    )

}
export default ViewQuatations;