import Request from "../Request";
const authlogin = async (data) => {
    return await Request('login', data);
};
const addClient = async (data) => {
    return await Request("user/client/add", data);
}
const UserList = async () => {
    return await Request("user/client/client", '', 'get');
}
const byProduct = async (data) => {
    return await Request("user/client/product/" + data, '', 'get');
}
const byProductId = async (data) => {
    return await Request("user/client/products/" + data, '', 'get');
}
const saveProduct = async (data) => {
    return await Request("user/client/products/save", data);
}
const getSummary = async () => {
    return await Request("user/client/summary", '', 'get');
}
const quatationStatus = async (data) => {
    return await Request("user/client/quatatonStatus/", data);
}
const viewProjectById = async (data) => {
    return await Request("user/client/viewQuataton/" + data, '', 'get');
}
const downloadPdf = async (data) => {
    return await Request("user/client/downloadPdf/" + data, '', 'get');
}
const editProject = async (data) => {
    return await Request("user/client/summary/edit/" + data, '', 'get');
}
const updateProductData = async (data) => {
    return await Request("user/client/summary/update" ,data);
}
const excelImport = async (data) => {
    return await Request("user/client/bulk-upload" ,data);
}
const UserSection = {
    authlogin,
    addClient,
    UserList,
    byProduct,
    byProductId,
    saveProduct,
    getSummary,
    quatationStatus,
    viewProjectById,
    downloadPdf,
    editProject,
    updateProductData,
    excelImport,
}
export default UserSection;