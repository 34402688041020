import TopBar from "../Nav/TopBar";
import { Table } from "react-bootstrap";
import { Form, FormControl, Button } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import UserSection from "../../../Helpers/Service/UserSection";
import Lottie from 'lottie-react';
import animationData from '../../../animations/loader_animation0.json';
const ViewClient = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        viewClient();
    }, [])

    const viewClient = async () => {
        setLoading(true);
        const response = await UserSection.UserList();
        if (response.data) {
            setLoading(false);
            setUsers(response.data);
        }

    }
    return (
        <TopBar>

            {users ?
                (<div className="col-md-12">

                    <Card >
                        <Card.Header>
                            <Card.Title>Add Client</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>SrNo</th>
                                            <th>Firm Name</th>
                                            <th>Address</th>
                                            <th>Phone No</th>
                                            <th>Email</th>
                                            <th>Contact Person</th>
                                            <th>Gst Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((item) => (
                                            <tr>
                                                <td className="border p-2 text-center">{item.id}</td>
                                                <td className="border p-2 text-center">{item.name}</td>
                                                <td className="border p-2 text-center">{item.address}</td>
                                                <td className="border p-2 text-center">{item.phone}</td>
                                                <td className="border p-2 text-center">{item.email}</td>
                                                <td className="border p-2 text-center">{item.persons}</td>
                                                <td className="border p-2 text-center">{item.gst}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                ) : (
                    <div class="loder">{
                        loading ? (
                            <Lottie
                                animationData={animationData}
                                loop={true}
                                autoplay={true}
                                style={{ height: '100px', width: '100px' }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    </div>
                )
            }
        </TopBar>
    )
}
export default ViewClient;