import { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserSection from '../../../Helpers/Service/UserSection';
import Swal from 'sweetalert2';

const SignUp = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = async (e) => {
        try {
            const response = await UserSection.authlogin({ email, password });
            
            if (response.status) {
                localStorage.setItem("user", response.data.token);
                localStorage.setItem("user_name", response.data.data.name);
                Swal.fire({
                    icon: 'success',
                    title: 'Login',
                    text: 'Wel-come' + "   " + response.data.data.name,
                    timer: 30000, // Automatically close after 3 seconds
                });
                localStorage.setItem("user", response.data.token);
                window.location.href = '/home';
            }
            else {
                navigate('/login');
            }
        }
        catch (error) {
            Swal.fire({
                icon: 'error', // 'failed' should be 'error' for the icon
                title: 'Failed',
                text: 'Incorrect email and password',
                timer: 3000, // Automatically close after 3 seconds
              });
        }

    };
    return (
        <div className='col-md-5 offset-3 mt-5'>
            <Card >
                <Card.Header>
                    <Card.Title>Login</Card.Title>
                </Card.Header>
                <Card.Body>

                    <Card.Text>
                        <Form className="p-3">

                            <Form.Group className="mb-3 " controlId="formFirstName">
                                <Form.Label>Email: </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 " controlId="formLastName">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>


                        </Form>
                    </Card.Text>

                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" type="submit" className="col-md-2 offset-5" onClick={handleSubmit}>
                        Login
                    </Button>
                </Card.Footer>

            </Card>
        </div>
    )

}
export default SignUp;