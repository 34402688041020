import { useState } from 'react';
import { Container, Nav, Row, Col, Navbar } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Home from "../home";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
const TopBar = ({ children }) => {
    const [menuVisible, setMenuVisible] = useState(true);
    const hideMenu = () => {
        setMenuVisible(!menuVisible);
    }
    return (
        <>
            {/* <Container fluid>
                <Row>
                    <Col md={3} className="bg-light sidebar">
                        <Sidebar />
                    </Col>
                    <Col md={9} className="content">
                        <Home />
                    </Col>
                </Row>
            </Container> */}
            <Navbar bg="dark" variant="dark" className='p-2'>
                <Navbar.Brand href="#home"> <FontAwesomeIcon icon={faBars} onClick={hideMenu} />
                </Navbar.Brand>
                <Navbar.Brand href="#home">Quotation Managment</Navbar.Brand>
                <Nav className="mr-auto">
                 
                </Nav>
            </Navbar>

            <div class="row main">
                {

                    <div class="col-md-3 ">
                        {menuVisible ? <Sidebar /> : ''}
                    </div>
                }
                <div className={`side-menu ${menuVisible ? 'col-md-9 mt-5' : 'mt-5 p-5'}`}>

                    {children}


                </div>
            </div>

        </>
    )
}
export default TopBar;