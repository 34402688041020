import TopBar from "../Nav/TopBar";
import { Table } from "react-bootstrap";
import { Form, FormControl, Button } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import UserSection from "../../../Helpers/Service/UserSection";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Autocomplete from "./Autocomplete";
import { tab } from "@testing-library/user-event/dist/tab";
import { handleApiError } from "../../../Helpers/handleApiError ";
import Swal from 'sweetalert2';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

import * as XLSX from 'xlsx';
const Quatation = () => {
    const [name, setName] = useState();
    const { projectId } = useParams();
    // const [tableData, setTableData] = useState([]);
    const [selectionId, setSelectionID] = useState('1');
    const [rows_id, setRowsId] = useState(1);
    const [tableData, setTableData] = useState([
        { id: rows_id, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: 'null', mrp: '', discount_price: '', discount_per: '', gst: '', total: '', discount: '', selection: selectionId, active: true, selection_name: null, client_id: null, type: 'add', check_gst: '' },
    ]);
    const [inputValue, setInputValue] = useState(['']);
    const [error, setError] = useState('');
    const [clientId, setClientId] = useState();
    const [amountType, setAmountType] = useState('price');
    const [secName, setSecName] = useState();
    const [loading, setLoading] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [gst, setGst] = useState(1);

    const [excludeGst, setexcludeGst] = useState(false);
    const [includeGst, setincludeGst] = useState(false);

    // const [projectId, setprojectId] = useState();
    const handleSelected = (selected) => {
        const filteredItems = [...tableData];
        const updatedItems = filteredItems.filter(item => item.sku_code !== '');
        const index = updatedItems.length;
        let hasgst;
        if (gst == 1) {
            hasgst = selected.gst;
        } else {
            hasgst = 0;
        }

        let ref_total = parseFloat((hasgst * selected.mrp) / 100);
        ref_total += parseFloat(selected.mrp);
        const getLartgest_Id = getLartgestId();
        const resdata = { id: getLartgest_Id, check: false, sku_code: selected.sku_code, brand: selected.brand, product_desc: selected.product_desc, finish: selected.finish, image: selected.image, quantity: '', area: 'null', mrp: selected.mrp, discount_price: '', discount_per: '', gst: hasgst, total: '', discount: 0, selection: selectionId, active: true, selection_name: secName, check_gst: selected.gst, total: ref_total };

        updatedItems[index] = resdata;


        // If there are still items left after filtering


        setTableData(updatedItems);
        setRowsId(rows_id + 1);
        console.log(tableData);
    };
    const saveproject = async () => {
        try {
            const mergedState = {
                tableData, // Spread the properties of state1
                selectionId,
                clientId,
            };
            Swal.fire({
                title: 'Do you want to continue?',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Save',
                cancelButtonText: 'Update',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    saveConfired(mergedState);
                    // setLoading(true);
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                    updateConfirmed(mergedState);
                }
            });


        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    const saveConfired = async (mergedState) => {
        const response = await UserSection.saveProduct(mergedState);
        if (response.status) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Data save Successfully',
                timer: 30000, // Automatically close after 3 seconds
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        setTableData(['']);

                    } else if (result.dismiss === Swal.DismissReason.cancel) {

                    }
                });
        }
        console.log(response);
    }

    const updateConfirmed = async () => {
        try {
            const filteredItems = [...tableData];
            const updatedItems = filteredItems.filter(item => item.sku_code !== '');
            // await setTableData(updatedItems);
            // setLoading(true);
            const mergedState = {
                tableData, // Spread the properties of state1
                selectionId,
                projectId,
                clientId,

            };
            const response = await UserSection.updateProductData(mergedState);
            if (response.status) {
                // setLoading(false);
                Swal.fire({
                    title: 'success',
                    icon: 'success',
                    html: `
                        <div>
                        <label>Project Update successfuly</label> </br>
                        <label>Project ID:</label>
                        <span>${response.project_id}</span>
                        </div>
                    `,

                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // removeState();
                        // setClient('');
                        // setselectionClient('');
                        // setSecName('');
                        // setLoading(false);

                        // document.getElementById('clientId').value = '';
                        // document.getElementById('sku_code');
                        window.location.href = '/quatation';
                    }
                    // setLoading(false);
                })

            }
        } catch (error) {
            // setLoading(false);
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }

    const changeQty = (e, index) => {
        if (e.target.value >= 0) {
            // const old_amount = updatedItems[index].total;
            const updatedItems = [...tableData];


            // const index = updatedItems.findIndex(item => item.id === id);
            updatedItems[index].quantity = e.target.value;
            let roundTotal = parseFloat(updatedItems[index].mrp * e.target.value);

            let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
            roundTotal += gst;
            // const roundTotal = updatedItems[index].mrp * updatedItems[index].quantity * updatedItems[index].gst / 100;
            // roundTotal+=gst;
            updatedItems[index].total = parseFloat((roundTotal) - (updatedItems[index].discount));
            // updatedItems[index].total += updatedItems[index].total +gst;
            // all_total +  updatedItems[index].total;
            // updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
            // setSub_Total(all_total + updatedItems[index].total);
            setTableData(updatedItems);
            // updatePrice(index);
        }

        else {
            const updatedItems = [...tableData];
            let roundTotal = parseFloat(updatedItems[index].mrp * 1);
            let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
            roundTotal += gst;

            updatedItems[index].total = roundTotal;
            setTableData(updatedItems);
            return 0;
        }
    }
    const setArea = (e, index) => {
        const updatedata = [...tableData];
        updatedata[index].selectionId = selectionId;
        updatedata[index].area = e.target.value;
        setTableData(updatedata);
    }
    const setDiscount = (e, index) => {
        // let value = parseFloat(e.target.value);
        let value = e.target.value;
        let dis = 0;
        let mrp = 0;
        const sku_code = e.target.value;
        const regex = /^[0-9.]*$/;

        let updatedItems = [...tableData];

        if (e.target.value != '') {
            if ((value > -1)) {

                let qty = updatedItems[index].quantity ? parseInt(updatedItems[index].quantity) : 1;
                let roundTotal = parseFloat(parseFloat(updatedItems[index].mrp) * qty);

                let gst = parseFloat((roundTotal * parseFloat(updatedItems[index].gst)) / 100);
                roundTotal += gst;

                if (amountType == 'price') {
                    // updatedItems[index].total =  updatedItems[index].total - total;
                    updatedItems[index].discount_price = value;
                    updatedItems[index].discount = value;
                    updatedItems[index].total = parseFloat(roundTotal - value);
                    updatedItems[index].sub_Total = updatedItems[index].total;
                    dis = updatedItems[index].discount_price * 100 / updatedItems[index].mrp + '00';
                    updatedItems[index].discount_per = dis;
                    // total = total + updatedItems[index].sub_Total;
                    updatedItems[index].discount_price = e.target.value;

                } else {

                    updatedItems[index].discount_per = value;
                    let totla_per = parseFloat((roundTotal * value) / 100);
                    updatedItems[index].discount = totla_per;
                    updatedItems[index].total = roundTotal - totla_per;
                    updatedItems[index].sub_Total = parseFloat((updatedItems[index].sub_Total) + (updatedItems[index].total));
                    dis = updatedItems[index].discount_per * updatedItems[index].mrp / 100;
                    updatedItems[index].discount_price = dis;
                    // updatedItems[index].discount_per = e.target.value;
                    // total = total + updatedItems[index].sub_Total;
                }
                setTableData(updatedItems);
                // setSub_Total(updatedItems[index].sub_Total);
                // updatePrice(index);
            }

            // }
            else {
                alert('Please select positive value');
                return 0;
            }
        } else {
            updatedItems[index].discount_per = '';
            updatedItems[index].discount_price = '';
            setTableData(updatedItems);
        }
    }
    const changeSelection = (e) => {
        let Row = 0;
        const newSelectionId = e.target.value;
        let updatedItems = [...tableData];

        let current_sel = '';
        var total_slots = '';
        setSelectionID(newSelectionId);

        for (let i = 0; i < updatedItems.length; i++) {
            if (updatedItems[i].brand == '') {
                total_slots += 1;
            }
            if (updatedItems[i].selection != newSelectionId) {
                updatedItems[i].active = false;
                setSecName('');

            } else {
                setSecName('');
                updatedItems[i].active = true;
                current_sel = updatedItems[i].selection_name;


            }
            if (updatedItems[i].selection == newSelectionId) {

                Row += 1;
            }
        }

        setTableData(updatedItems);
        setSecName(current_sel);
        // if (Row <= 0) {
        //     console.log('CAME');
        //     addRow(e, newSelectionId);
        // }
    }
    const changeName = (e) => {
        console.log(e.target.value);
        var value = e.target.value;
        setSecName(value);
        let updatedItems = [...tableData];
        for (let i = 0; i < updatedItems.length; i++) {
            if (updatedItems[i].selection == selectionId) {
                updatedItems[i].selection_name = value;
                setSecName(updatedItems[i].selection_name);
            }
            else {
                setSecName();
            }

        }
        setTableData(updatedItems);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UserSection.editProject(projectId);
                const newData = [];
                // setselectionClient(response.data[0].client_id);
                setSecName(response.data[0].secname);
                response.data.forEach((item, index) => {
                    const productData = item.project_save[index];
                    console.log(item);
                    newData.push({
                        id: item.project_id,
                        check: false,
                        sku_code: item.sku_code,
                        brand: productData.project.brand,
                        product_desc: productData.project.product_desc,
                        finish: productData.project.finish, // Assuming 'finish' is directly accessible from product_data
                        image: productData.project.image, // You can set the default value or access it if available
                        quantity: item.qty,
                        area: productData.area,
                        mrp: productData.project.mrp,
                        discount_price: item.disc_price,
                        discount_per: item.disc_per,
                        gst: productData.gst,
                        total: productData.total, // You can set the default value or access it if available
                        discount: '', // You can set the default value or access it if available
                        selection: item.section_id,
                        active: item.section_id == selectionId ? true : false,
                        selection_name: item.secname,
                        client_id: item.client_id,
                        type: 'update',
                    });
                });
                setTableData([]);
                setTableData(newData);
                console.log(newData);

            } catch (error) {
                // Handle errors if any
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the inner async function immediately
    }, [projectId]);

    //bulk upload
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Access the uploaded file
        // setFiles(file);

        const reader = new FileReader();
        let allSheetData = [];

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });


            workbook.SheetNames.forEach((sheetName, index) => {
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });


                if (index == 0) {

                    allSheetData[sheetName] = jsonData.slice(0);

                } else {

                    allSheetData[sheetName] = jsonData.slice(0);

                }
            });
            const sectionsArray = Object.entries(allSheetData);
            // const dataString  = JSON.stringify(allSheetData);
            importcsvfile1(sectionsArray);
            setExcelData(sectionsArray);
            // setError('');
        };

        reader.readAsArrayBuffer(file);

    };

    const importcsvfile1 = async (data) => {
        try {
            const updatedItems = [...tableData];
            setLoading(true);
            if (excelData) {
                const large_id = getLartgestId();
                const importData = await UserSection.excelImport(data);
                setLoading(false);
                // setSecName(importData.data[0].sheet_name);
                // setSelectionID(importData.data[0].section_id)
                let ref_total = 0;
                const has_gst = 0;
                const newData = importData.data.map((item, index) => ({
                    has_gst: gst == 1 ? parseFloat(item.gst) : 0,
                    ref_total: gst == 1 ? (parseFloat(item.gst) * parseFloat(item.mrp)) / 100 : 0,
                    id: large_id + (index + 1),
                    check: false,
                    sku_code: item.sku_code,
                    brand: item.brand,
                    product_desc: item.product_desc,
                    finish: item.finish,
                    image: item.img, // Assuming image is not provided in the response
                    quantity: item.qty, // You can populate these fields if available in the response
                    area: item.area,     // or leave them empty
                    mrp: item.mrp,
                    discount_price: item.discount_price,
                    discount_per: ((parseFloat(item.discount_price)) * 100) / parseFloat(item.mrp),
                    gst: gst == 1 ? item.gst : 0,
                    total: gst == 1 ? (parseFloat(item.gst_total)) : (parseFloat(item.non_gst)),

                    total: ((parseFloat(item.total) * (parseFloat(has_gst)) - parseFloat(item.discount_price))),
                    discount: '',
                    selection: item.section_id ? item.section_id : selectionId, // Assuming selectionId is defined elsewhere
                    active: item.section_id == selectionId ? true : false,
                    selection_name: secName,
                }));

                // console.log(newData);
                if (updatedItems.length > 0 && updatedItems[0] && updatedItems[0].sku_code === '') {
                    setTableData(newData);
                    setRowsId(tableData.length);
                }
                else {
                    const index = updatedItems.length;
                    updatedItems[index] = newData;
                    setRowsId(26);

                    setTableData(prevData => [...prevData, ...newData]);
                }
                if (gst == 1) {
                    setexcludeGst(true);
                }
                else {
                    setincludeGst(true);
                }

            } else {
                setError('Please select a file.'); // Set error message

            }
        } catch (error) {
            console.error('Error uploading file:', error);
            // Handle errors here
        }
    };

    const getLartgestId = () => {
        const largestId = tableData.reduce((maxId, item) => {
            return Math.max(maxId, item.id);
        }, -Infinity);
        return largestId + 1;
    }

    const changeGst = (id) => {
        setGst(id);
        let updatedItems = [...tableData];
        const lastItemIndex = updatedItems.length - 1;
        const lastItem = updatedItems[lastItemIndex];
        const total_gst = parseFloat(updatedItems[lastItemIndex].mrp * updatedItems[lastItemIndex].check_gst) / 100;
        if (gst == 1) {

            updatedItems[lastItemIndex].total -= total_gst;
            updatedItems[lastItemIndex].gst = 0;
            // Now, set the updated items back to state
            setTableData(updatedItems);
        }
        else {

            updatedItems[lastItemIndex].total += total_gst;

            updatedItems[lastItemIndex].gst = updatedItems[lastItemIndex].check_gst;
            // Now, set the updated items back to state
            setTableData(updatedItems);
        }

    }

    const removeRow = (e, id) => {
        e.preventDefault();
        if (tableData.length <= 1) {
            return; // No rows to remove
        }
        let updatedItems = [...tableData];
        let u1 = [];
        for (let i = 0; i < updatedItems.length; i++) {

            if (updatedItems[i].check == true) {
                if (updatedItems.length <= 1) {
                    return; // No rows to remove
                }
                // updatedItems = updatedItems.filter((item, index) => index !== i);
                updatedItems = updatedItems.filter((item) => !item.check);

            }


        }
        setTableData(updatedItems);

    }
    const resetState = () => {
        window.location.href = "/quatation";
    };
    const addRow = (newSelectionId = null) => {
        // e.preventDefault();
        console.log(newSelectionId);
        var total_slot = 0;
        if (null == newSelectionId) {
            newSelectionId = selectionId;
        }
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].selection == newSelectionId) {
                total_slot += 1;

            }
        }
        if (total_slot >= 300) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Rows Limit riched = 300 ',
            });
            return false;
        }
        // var row_id = tableData.length + 1;
        // let r_id = rows_id + 1;
        const getLartgest_Id = getLartgestId();
        setRowsId(getLartgest_Id);

        const newRow = { id: getLartgest_Id, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discound_price: '', discound_per: '', gst: '', selection: newSelectionId, active: true, selection_name: '' };
        setTableData([...tableData, newRow]);
        console.log(newRow);
    };
    let total_amount = 0;
    return (
        <TopBar>
            <div className="col-md-12">

                <div className="row ml-2">
                    <div className="col-md-4 mb-4">
                        <label>Bulk Upload</label>
                        <input type="file" className="form-control" onChange={handleFileChange}></input>
                    </div>
                    <div className="col-md-4 mb-4 offset-6 search_box">
                        <Autocomplete onSelected={handleSelected} />
                    </div>
                </div>

                <Card className="mt-5">
                    <Card.Header>
                        <div className="row">
                            <div className=" col-md-2">
                                <select className="form-control" onChange={(e) => changeSelection(e)}>
                                    <option value='1'>Selection 1</option>
                                    <option value='2'>Selection 2</option>
                                    <option value='3'>Selection 3</option>
                                    <option value='4'>Selection 4</option>
                                    <option value='5'>Selection 5</option>
                                    <option value='6'>Selection 6</option>
                                </select>
                            </div>
                            <div className=" col-md-3">
                                <input type="text" className="form-control" placeholder="Enter selectiion name" name='secName' value={secName} onChange={(e) => changeName(e)} />
                            </div>
                            <div className=" col-md-3">
                                <input type="text" className="form-control" placeholder="Enter client Id" name="clientId" value={clientId} onChange={(e) => setClientId(e.target.value)} />
                            </div>
                            <div className=" col-md-3">
                                <span>
                                    <b><label>Price   </label></b>
                                    <input type="radio" className="form-check-input" placeholder="Enter client name" value='price' onChange={e => setAmountType('price')} checked={amountType === 'price' ? true : false} />
                                </span>
                            </div>
                            <div className=" col-md-3">
                                <b><label>Percentage  </label></b>
                                <input type="radio" className="form-check-input" placeholder="Enter client name" value='percentage' onChange={e => setAmountType('percentage')} checked={amountType === 'percentage' ? true : false}></input>
                            </div>
                            <fieldset className="border border-gray-300 rounded-md p-3">
                                <legend className="text-gray-700 font-medium">GST</legend>
                                <div className="flex gap-x-3">
                                    <div className="flex items-center gap-x-1">
                                        <input type="radio" className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary" name='get' value="1" onChange={(e) => changeGst(1)} checked={gst === 1 ? true : false} disabled={includeGst} ></input>
                                        <label htmlFor="discountPercent">inclusive  </label>
                                    </div>
                                    <div className="flex items-center gap-x-1">
                                        <input type="radio" className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary" name='get' value="0" onChange={(e) => changeGst(0)} checked={gst === 0 ? true : false} disabled={excludeGst}></input>
                                        <label htmlFor="discountPrice"> exclusive </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        {/* <Card.Title>Quatation</Card.Title> */}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>sku Code</th>
                                        <th>finsh</th>
                                        <th>Image</th>
                                        <th>Quantity</th>
                                        <th>Area</th>
                                        <th>Mrp</th>
                                        <th>Discount Price</th>
                                        <th>Discount %</th>
                                        <th>Gst</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.length >= 0 &&
                                        tableData.map(function (row, index) {
                                            total_amount += parseFloat(row.total);
                                            if (row.active) {
                                                // total_amount += parseFloat(row.total);
                                                return <tr key={row.id}>
                                                    <td className=''><input type='checkbox' class='' ></input></td>
                                                    <td >{row.brand}</td>
                                                    {/* <td size='1' className='w-40'>{row.product_desc}</td> */}
                                                    <td>{row.sku_code}</td>
                                                    <td>{row.finish}</td>
                                                    <td className='w-24 h-24 col-md-2'> <img src={row.image} style={{ width: '100px', height: '100px' }} /></td>
                                                    <td><input className="form-control" type="text" id="" name="quantity" value={row.quantity} size='3' onChange={(e) => changeQty(e, index)}></input></td>
                                                    <td className='col-md-2'>
                                                        <input type='radio' name={'area' + [row.id]} className='text-center' onChange={(e) => setArea(e, index)} value='shower'></input>Shower <br />
                                                        <input type='radio' name={'area' + [row.id]} className='text-center' onChange={(e) => setArea(e, index)} value='basin'></input>Basin<br />
                                                        <input type='radio' name={'area' + [row.id]} className='text-center' onChange={(e) => setArea(e, index)} value='ewc'></input> EWC
                                                        <input type='radio' name={'area' + [row.id]} className='text-center' onChange={(e) => setArea(e, index)} value='kitchen'></input>Kitchen<br />
                                                        <input type='radio' name={'area' + [row.id]} className='text-center' onChange={(e) => setArea(e, index)} value='Faucets'></input> Faucets
                                                    </td>
                                                    <td>{row.mrp}</td>
                                                    {
                                                        row.discount_price ? (
                                                            <td className='w-24'><input className="px-3 py-1 text-gray-700 border rounded-md w-16" type="text" id="" name="discount_price" disabled={amountType === 'price' ? false : true} size='3' onChange={(e) => setDiscount(e, index)} value={row.discount_price}></input></td>
                                                        ) : (
                                                            <td className='w-24'><input className="px-3 py-1 text-gray-700 border rounded-md w-16" type="text" id="" name="discount_price" disabled={amountType === 'price' ? false : true} size='3' onChange={(e) => setDiscount(e, index)} ></input></td>
                                                        )
                                                    }
                                                    {
                                                        row.discount_per ? (
                                                            <td className='w-24 '><input className="px-3 py-1 text-gray-700 border rounded-md w-16" type="text" id="" name="discount_per" disabled={amountType == 'percentage' ? false : true} onChange={(e) => setDiscount(e, index)} value={row.discount_per} size='3'></input></td>
                                                        ) : (
                                                            <td className='w-24 '><input className="px-3 py-1 text-gray-700 border rounded-md w-16" type="text" id="" name="discount_per" disabled={amountType == 'percentage' ? false : true} onChange={(e) => setDiscount(e, index)} size='3'></input></td>
                                                        )
                                                    }
                                                    {/* <td className='w-24'><input className="px-3 py-1 text-gray-700 border rounded-md w-16" type="text" id="" name="discount_price" disabled={amountType === 'price' ? false : true} size='3'  onChange={(e) => setDiscount(e, index)} value={row.discount_price}></input></td> */}


                                                    <td>{row.gst}</td>

                                                    <td>{row.total}</td>
                                                </tr>
                                            }
                                        })}

                                </tbody>
                                <tbody>
                                    <tr>
                                        <td colSpan={11} className="text-left ">Total Amount :</td>
                                        <td className="font-bold">{total_amount.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                                <tfoot className="" style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <button className="btn btn-primary  mt-2" onClick={() => addRow(null)}>
                                        Add Row
                                    </button>
                                    <button className="btn btn-primary  mt-2" onClick={saveproject}>Save</button>
                                    <button className="btn btn-primary mt-2" onClick={(e) => removeRow(e)}>
                                        Delete Rows
                                    </button>
                                    <button className="btn btn-primary mt-2" onClick={(e) => resetState(e)}>
                                        Reset                        </button>
                                </tfoot>
                            </Table>
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
        </TopBar>
    )
}
export default Quatation;